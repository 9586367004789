import React, { useState } from "react";
import { useFetch } from "../../../Hooks/useFetch";
import { IADMINProfile } from "../../../Interfaces/IProfile";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IADMINUser } from "../../../Interfaces/IUser";
import { Check, Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { UserEdit } from "./UserEdit";


interface IProps {
    profileArray: IADMINUser[];
    setProfileArray: Function;
}

const columns: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90 
    },
    { 
        field: 'Admin', 
        headerName: 'Admin', 
        width: 100,
        renderCell: (params: GridRenderCellParams<any, IADMINUser>) => (params.row.is_superuser) ? <Check/> : <Close/>
    },
    { 
        field: 'Activ', 
        headerName: 'Activ', 
        width: 100,
        renderCell: (params: GridRenderCellParams<any, IADMINUser>) => (params.row.is_active) ? <Check/> : <Close/>
    },
    { 
        field: 'Email', 
        headerName: 'Email', 
        valueGetter: (value, row) => row.email,
        flex: 1
    },
    { 
        field: 'first', 
        headerName: 'Username', 
        valueGetter: (value, row) => row.username,
        flex: 1
    },
]


export const UserOverview:React.FC<IProps> = ({profileArray, setProfileArray}) => {
    const [idProfile,setIdProfile] = useState<number|null>(null);

    return(
        <>
            <Dialog open={idProfile !== null} onClose={() => setIdProfile(null)} maxWidth="sm" fullWidth>
                <DialogContent>
                    {(idProfile !== null) &&
                        <UserEdit
                            idProfile={idProfile}
                            setIdProfile={setIdProfile}
                            profileArray={profileArray}
                            setProfileArray={setProfileArray}
                        />
                    }
                </DialogContent>
            </Dialog>


            <Button onClick={() => setIdProfile(-1)} sx={{float: "right"}} variant="outlined">Add user</Button>
            <br />
            <Box sx={{mb: 3}}/>
            <DataGrid
                //localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={profileArray}
                columns={columns}
                //getRowId={(row) => row.id}
                onRowClick={(params,event,details) => setIdProfile(Number(params.id))}
                autoHeight
            />
        </>
    )
}