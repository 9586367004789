import React from "react";
import { IProfile } from "../../Interfaces/IProfile";
import { Avatar } from "@mui/material";



export function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  




interface IProps {
    profileObject: IProfile;
    username: string;
    size?: "small" | "medium" | "big"
}


export const ProfileAvatar:React.FC<IProps> = ({profileObject, username, size}) => {

    const stringAvatar = (name: string) => {
      let value = 25;

      if (size !== undefined) {
        if (size === "medium") {
          value = 100;
        }
        else if (size === "big") {
          value = 150;
        }
      }

      return {
        sx: {
          bgcolor: stringToColor(name),
          width: value,
          height: value
        },
        children: `${name[0]}`,
      };
    }

    if (profileObject.avatar === "") {
        return <Avatar {...stringAvatar(username)} />
    } else {
        return <Avatar {...stringAvatar(username)} src={`data:image/png;base64, ${profileObject.avatar}`} />
    }
}