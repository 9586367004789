import { Box, Typography } from "@mui/material";
import React from "react";


export const Imprint:React.FC = () => {
    return(
        <Box sx={{m: 10, mt: 0}}>
            <Typography variant="h5">Impressum</Typography>

            <Box sx={{m: 2, mt: 5}}>
                <Typography variant="h6">Zeitgeist Technologies UG (haftungsbeschränkt)</Typography>
            </Box>

            <Box sx={{m: 2, mt: 5}}>
                <Typography>c/o Philipp Schulenburg</Typography>
                <Typography>Kaiser-Friedrich-Straße 51</Typography>
                <Typography>10627 Berlin - GERMANY</Typography>
                <Typography>Tel. - ausstehend - </Typography>
            </Box>

            <Box sx={{m: 2, mt: 5}}>
                <Typography>Geschäftsführer: Philipp Schulenburg</Typography>
                <Typography>Amtsgericht: Berlin-Charlottenburg HRB 261120, Berlin</Typography>
                <Typography>UStIdNr.: - 27/604/50393 -</Typography>
            </Box>

            <Box sx={{m: 2, mt: 5}}>
                <Typography>info@zeitgeist.eu</Typography>
            </Box>
            
        </Box>
    )
}