import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import md5 from 'md5';

import "./Login.css"
import { Sigup } from "./Sigup";
import { ResetPassword } from "./ResetPassword";
import { Copyright } from "@mui/icons-material";


export const Login:React.FC = () => {
    const [email,setEmail] = useState("");
    const [pw,setPw] = useState<string>("");
    const [msg,setMsg] = useState("");
    //
    const [isOpenWelcome, setIsOpenWelcome] = useState(true);


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        console.log("LEL")
        async function tryLogin() {
            if (email !== "" && pw !== "") {
                console.log("LEL")
                await fetch("/api/user/login/",{
                    method: "POST",
                    headers :  { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "username" : email, "password" : md5(pw) } as any)
                })
                .then(res => {
                    if (res.status == 200) {
                        console.log("success");
                        return res.json();
                    } else 
                    {
                        throw Error(String(res.status))
                    }
                })
                .then(res => {
                        localStorage.setItem('Token', res["token"]);
                        localStorage.setItem('profile', res["profile"]["id"]);
                        localStorage.setItem('isSuperuser', res["user"]["is_superuser"]);
                        //localStorage.setItem('role', res["Role"]);
                        //localStorage.setItem('scope', res["Scope"]);
                        window.location.reload(); 
                })
                .catch( error => {
                    if (String(error).includes("401")) {
                        setMsg("Die Anmeldedaten scheinen nicht korrekt zu sein")
                    } else if (String(error).includes("403")) {
                        setMsg(`Ihr Konto wurde deaktiviert!`);
                    } else {
                        setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
                    }
                    setEmail("");
                    setPw("");
                })
            } else {
                setEmail("");
                setPw("");
            }
        }
        tryLogin();
    }



    return(
        <>
            <Dialog open={isOpenWelcome} onClose={() => setIsOpenWelcome(false)}>
                <DialogContent>
                    <Alert severity="info">
                        We are delighted that you have found our website. We are currently in closed beta. Therefore a public registration is deactivated. 
                        <br/>
                        <br/>
                        If you are interested please contact us at info[a]zeitgeist.eu
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenWelcome(false)}>Close</Button>
                </DialogActions>
            </Dialog>


            <Grid container sx={{p: 2, mt: "5%"}}>
                <Grid item sm={1} />
                <Grid item sm={4} className="container">
                    <Typography variant="h3">Zeitgeist</Typography>
                    <img className="imgLogin" src="/logo.png" alt="Logo" height={"30%"} />
                </Grid>

                <Grid item sm={1} />

                <Grid item sm={4}>
                    <Box className="loginBox">
                        <form onSubmit={handleSubmit} autoComplete="on">
                            <Collapse in={msg !== ""}>
                                <Alert severity="info">The login wasn't successfully!</Alert>
                            </Collapse>
                            <Grid container >
                                <Grid item sm={2}/>
                                <Grid item xs={8} sx={{mt: (msg === "") ? 10 : 4 }}>
                                    <TextField
                                        id="username"
                                        label="Username" 
                                        fullWidth
                                        value={email} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setEmail(String(event.target.value))} 
                                        required
                                        onFocus={() => setMsg("")}
                                    />
                                </Grid>
                                <Grid item sm={2}/>

                                <Grid item sm={2}/>
                                <Grid item xs={8} sx={{mt: 2 }}>
                                    <TextField
                                        id="Password"
                                        label="Password" 
                                        type="Password"
                                        fullWidth
                                        value={pw} 
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setPw(String(event.target.value))} 
                                        required
                                        onFocus={() => setMsg("")}
                                    />
                                </Grid>
                                <Grid item sm={2}/>

                                <Grid item sm={2}/>
                                <Grid item xs={8} sx={{mt: 5 }}>
                                    <Button type="submit" variant="contained" sx={{minHeight: 50}} fullWidth>Login</Button>
                                </Grid>
                                <Grid item sm={2}/>

                                <Grid item sm={2}/>
                                <Grid item xs={8} sx={{mt: 1 }}>
                                    <ResetPassword/>
                                </Grid>
                                <Grid item sm={2}/>



                                <Grid item sm={2}/>
                                <Grid item xs={8} sx={{mt: 15, minHeight: 100}}>
                                    <Sigup/>
                                </Grid>
                                <Grid item sm={2}/>
                            </Grid> 
                        </form>
                    </Box>
                </Grid>

                <Grid item sm={1} />


            </Grid>

            <Box sx={{ml: 10, mt: "10%"}}>
                Zeitgeist <Copyright sx={{mt: 1, fontSize: "16px"}}/>
                <Link sx={{ml: 5, fontSize: "12px", color: "#A8A8A8"}} href="/imprint">Imprint</Link>
            </Box>
            
            
        </>
    )
}