import { Grid, Link, Typography } from "@mui/material";
import React from "react";
import { Imprint } from "../../SmallSites/Imprint";


export const PublicImprint:React.FC = () => {
    return(
        <>
            <Grid container>
                <Grid item sm={12} sx={{height: 100, p: 8, mb: 8}}>
                    <Grid container sx={{}}>
                        <Grid item xs={6} sm={6} >
                            <Typography variant="h3">
                                <Link sx={{textDecoration: 'none' }} href="/">Zeitgeist</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} ></Grid>
                        <Grid item xs={0} sm={3}></Grid>
                    </Grid>
                </Grid>

                <Grid item sm={3} lg={2} sx={{borderRight:  "1px solid" }}></Grid>

                <Grid item sm={6} lg={8}> 
                    <Imprint/>
                </Grid>

                <Grid item sm={3} lg={2} sx={{borderLeft:  "1px solid" }}>
                    
                </Grid>
            </Grid>
        </>
    )
}