import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteFetch, useFetch } from "../../Hooks/useFetch";
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { ArticleCard } from "../Article/ArticleCard";
import { IArticleFullObject } from "../../Interfaces/IArticle";
import { IArticleParagraph } from "../../Interfaces/IArticleParagraph";
import { IArticlePicture } from "../../Interfaces/IArticlePicture";
import { Delete } from "@mui/icons-material";

export const Article:React.FC = () => {
    const params = useParams();
    const navigation = useNavigate();
    //
    const [articleObject,setArticleObject,statusCode] = useFetch<IArticleFullObject>("/article/",params.id);
    //
    const [isOpenConfirmDelete,setIsOpenConfirmDelete] = useState(false);
    const [wasSuccessfullyDeleted,setWasSuccessfullyDeleted] = useState(true);
    //
    let ownIdProfile = Number(localStorage.getItem("profile"));
    let isSuperuser = (localStorage.getItem("isSuperuser") === "true");

    const getIsEditOpen = () => {
        if (articleObject !== undefined) {
            if (isSuperuser) {
                return true
            }
            else {
                return (
                    (typeof articleObject.Author === "number") 
                    ? (articleObject.Author === ownIdProfile)
                    : (articleObject.Author.id === ownIdProfile)
                )
                
            }
        }
    }


    const genStructure = (paragraphs:IArticleParagraph[],pictures:IArticlePicture[]) => {
        let returnComponents:React.ReactElement[] = [];
        let maxPosParagraphs = Math.max(...paragraphs.map(x => x.position));
        let maxPosPicture = Math.max(...pictures.map(x => x.position));
        let maxPos = 0;

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        Array.from({ length: maxPos }, (x, i) => {
            let foundParagraph = paragraphs.find(x => x.position === (i+1));
            let foundPicture = pictures.find(x => x.position === (i+1));

            returnComponents.push(
                <>
                    {(foundParagraph) && 
                        <Typography sx={{mt: 2}}>{foundParagraph.text}</Typography>
                    }
                    {(foundPicture) && 
                        <Box sx={{mt: 5}}   display="flex"
                        justifyContent="center"
                        alignItems="center">
                            <img width="50%" src={`data:image/png;base64, ${foundPicture.picture}`} title={foundPicture.pictureName}/>
                        </Box>
                    }
                </>
            )
        })

        return(
            <>{[...returnComponents]}</>
        )
    }

    const handleWasDeleted = (wasDeleted:boolean) => {
        setIsOpenConfirmDelete(false)

        if (wasDeleted) {
            navigation("/")
        }
        else {
            setWasSuccessfullyDeleted(false)
        }
    }


    const handleDelete = () => {
        if (articleObject !== undefined) {
            deleteFetch("/article/",articleObject?.id,handleWasDeleted)
        }
        
    }


    if (statusCode !== null && statusCode !== 200) { return <Alert severity="info">Can't load article. Try later</Alert> }
    else if (articleObject === undefined) { return <CircularProgress/> }
    else {
        return(
            <>
                <Dialog open={isOpenConfirmDelete} onClose={() => setIsOpenConfirmDelete(false)}>
                    <DialogContent>
                        <Alert severity="info">
                            Are you sure you want to delete the existing article? 
                            <br/>This article cannot be restored later
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleDelete}>Delete</Button>
                        <Button variant="contained" onClick={() => setIsOpenConfirmDelete(false)}>Close</Button>
                    </DialogActions>
                </Dialog>


                <ArticleCard articleObject={articleObject} disableMoreButton>
                    <>
                        <Box sx={{float: "right"}}>
                            <Collapse in={getIsEditOpen()}>
                                <IconButton onClick={() => setIsOpenConfirmDelete(true)}><Delete/></IconButton>
                            </Collapse>
                        </Box>
                        <Collapse in={!wasSuccessfullyDeleted}>
                            <Alert severity="error">
                                Could not delete the article
                            </Alert>
                        </Collapse>
                        <br />
                        <br />
                        <br />
                        <Box>
                            {genStructure(articleObject.paragraphs,articleObject.pictures)}
                        </Box>
                    </>
                </ArticleCard>
            </>

        )
    }
}