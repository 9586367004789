import { Feed, FeedOutlined, SettingsOutlined } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";


export const NavBar:React.FC = () => {
    const navigation = useNavigate();
    const isSuperuser = localStorage.getItem("isSuperuser") === "true";


    return(
        <>
            <List dense>
                <ListItem>
                    <ListItemButton>
                        <ListItemText onClick={() => navigation("/")}>Home</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton>
                        <ListItemText onClick={() => navigation("/discovery")}>Discover</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton>
                        <ListItemText onClick={() => navigation("/publish")}>Publish</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton>
                        <ListItemText onClick={() => navigation("/profile/own")}>Profile</ListItemText>
                    </ListItemButton>
                </ListItem>

                {(isSuperuser) &&
                    <>
                        <ListItem>
                            <ListItemButton>
                                <ListItemText onClick={() => navigation("/admin")}>Administration</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </>
                }

            </List>

            <List>
                <ListItem>
                    <ListItemButton>
                        <ListItemIcon onClick={() => navigation("/profile/own/edit")}><SettingsOutlined sx={{fontSize: 35}}/></ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>

            <List sx={{ontSize: "12px", color: "#A8A8A8"}} dense>
                <ListItem>
                    <ListItemButton>
                        <ListItemText onClick={() => navigation("/imprint")}>Imprint</ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>

        </>
    )
}
/*

            <Box sx={{ml: 10, mt: "10%"}}>
                Zeitgeist <Copyright sx={{mt: 1, fontSize: "16px"}}/>
                <Link sx={{ml: 5, fontSize: "12px", color: "#A8A8A8"}} href="/imprint">Imprint</Link>
            </Box>
*/