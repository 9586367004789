import { Grid } from "@mui/material";
import React from "react";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { ISystemMail } from "../../../Interfaces/ISystemMail";
import { CustomEditor } from "../../generic/CustomEditor";



interface IProps {
    systemMailObject: ISystemMail;
    setSystemMailObject: Function;
}


export const SystemMailEdit:React.FC<IProps> = ({systemMailObject, setSystemMailObject}) => {
    return(
        <>
            <Grid container sx={{mt: 2}} spacing={2}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Title"
                        attr="Title"
                        type="string"
                        object={systemMailObject}
                        setObject={setSystemMailObject}
                        size="small"
                        
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Subject"
                        attr="Subject"
                        type="string"
                        object={systemMailObject}
                        setObject={setSystemMailObject}
                        size="small"
                        
                    />
                </Grid>

                <CustomEditor
                    txt={systemMailObject.Body}
                    setTxt={(newString:string) => setSystemMailObject({...systemMailObject, Body: newString})}
                />
            </Grid>
        </>
    )
}