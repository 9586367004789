import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { ArrowDownward, ArrowUpward, Delete, Upload } from "@mui/icons-material";
import { IArticlePicture } from "../../../Interfaces/IArticlePicture";
import { IArticleParagraph } from "../../../Interfaces/IArticleParagraph";


interface IProps {
    articlePicture: IArticlePicture;
    pictures: IArticlePicture[];
    setPictures: Function;
    paragraphs: IArticleParagraph[];
    setParagraphs: Function;
}



export const ArticlePictureEdit:React.FC<IProps> = ({articlePicture,pictures,setPictures, paragraphs, setParagraphs}) => {
    const [editPicture, setEditPicture] = useState(articlePicture);
    //
    const inputUpload = useRef<HTMLInputElement | null>(null);

    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return [String(result_base64).split(",")[1],file.name];
    }


    useEffect(() => {
        setPictures([
            ...pictures.map(x => x.id === editPicture.id ? editPicture : x)
        ])
    },[editPicture])


    const handleFilesUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateProfile = {...editPicture};
            [updateProfile.picture, updateProfile.pictureName] = await fileToBase64(currentFile);

            setEditPicture({...updateProfile})
        }
    }
    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }

    const handleDelete = () => {
        let current = editPicture.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position > current) ? {...x, position: x.position-1} : x);
        localPictures = localPictures.map(x => (x.position > current) ? {...x, position: x.position-1} : x);

        setPictures([
            ...localPictures.filter(x => x.id !== editPicture.id)
        ])

        setParagraphs([
            ...localParagrap
        ])
    }

    const handleUp = () => {
        let current = editPicture.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position === current-1) ? {...x, position: x.position+1} : x);
        localPictures = localPictures.map(x => (x.position === current-1) ? {...x, position: x.position+1} : x);

        setPictures([
            ...localPictures.map(x => x.id === editPicture.id ? {...editPicture, position: current-1} : x)
        ])

        setParagraphs([
            ...localParagrap
        ])
    }

    const handleDown = () => {
        let current = editPicture.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position === current+1) ? {...x, position: x.position-1} : x);
        localPictures = localPictures.map(x => (x.position === current+1) ? {...x, position: x.position-1} : x);

        setPictures([
            ...localPictures.map(x => x.id === editPicture.id ? {...editPicture, position: current+1} : x)
        ])

        setParagraphs([
            ...localParagrap
        ])
    }

    const disableDown = () => {
        let maxPosParagraphs =0;
        let maxPosPicture =0; 
        let maxPos = 0;

        if (paragraphs.length > 0) {
            maxPosParagraphs = Math.max(...paragraphs.map(x => x.position))
        } 
        if (pictures.length > 0) {
            maxPosPicture = Math.max(...pictures.map(x => x.position));
        } 

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        return (editPicture.position === maxPos);
    }



    return(
        <>
            <input 
                hidden 
                ref={inputUpload} 
                type="file" 
                accept="image/png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />

            <Box sx={{display: "flex", mt: 3, mb: 5}}>
                <Box sx={{width: "100%"}}>
                    {(editPicture.picture !== "")
                        &&                        
                            <Box sx={{mt: 5}}   
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                    <img width="50%" src={`data:image/png;base64, ${editPicture.picture}`} title={editPicture.pictureName}/>
                            </Box>
                        
                    }
                    <Button sx={{float: "right"}} onClick={() => (inputUpload !== null) && inputUpload.current?.click()} ><Upload/>Upload</Button>
                    <Box sx={{width: "100%", mt: 2}}> 
                        <CustomeTextField
                            attr="pictureName"
                            label="Name of picture"
                            type="string"
                            object={editPicture}
                            setObject={setEditPicture}
                            required
                            size="small"
                        />
                    </Box>

                </Box>
                <Box sx={{width: 20, ml: 2}}>
                    <IconButton onClick={handleUp} disabled={editPicture.position === 1}><ArrowUpward/></IconButton>
                    <IconButton onClick={handleDown} disabled={disableDown()}><ArrowDownward/></IconButton>
                    <IconButton onClick={handleDelete}><Delete/></IconButton>
                </Box>
            </Box>
        </>
    )
}