import React, { useState } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { Alert, CircularProgress } from "@mui/material";
import { UserEdit } from "../Profile/UserEdit";


export const ProfileOwn:React.FC = () => {
    const [ownUser,setWwnUser, statusCodeUser] = useFetch<any>("/profile/own");

    if (statusCodeUser !== null && statusCodeUser !== 200) { return <Alert severity="error">Error!</Alert> }
    else if (ownUser === undefined) { return <CircularProgress/> } 
    else {
        return(
            <>
                <UserEdit
                    ownUserObject={ownUser}
                    setOwnUser={setWwnUser}
                />
            </>
        )
    }
}