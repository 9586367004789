import React from "react";
import { useFetch } from "../../../Hooks/useFetch";
import { ISystemMail } from "../../../Interfaces/ISystemMail";
import { Alert, Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { SystemMailOverview } from "../../Admin/SystemMail/SystemMailOverview";
import { IADMINProfile } from "../../../Interfaces/IProfile";
import { UserOverview } from "../../Admin/User/UserOverview";
import { IADMINUser } from "../../../Interfaces/IUser";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, mt: 5 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }





export const AdminOverview:React.FC = () => {
    const [systemMailArray,setSystemMailArray,statusCodeSystemMail] = useFetch<ISystemMail[]>("/systemmail");
    const [profileArray, setProfileArray, statusCodeProfile]  = useFetch<IADMINUser[]>("/user/admin");
    //
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    if (
        (statusCodeSystemMail !== null  && statusCodeSystemMail !== 200 && statusCodeSystemMail === 403 )
        || (statusCodeProfile !== null  && statusCodeProfile !== 200 && statusCodeProfile === 403 )
      ) {
        if (statusCodeSystemMail === 403 || statusCodeProfile === 403) { return <Alert severity="error">Access denied!</Alert> }
        else { return <Alert severity="error">Error!</Alert> }
    }
    else if (systemMailArray === undefined || profileArray === undefined) { return <CircularProgress/> }
    else {
        return(
            <>
                <Typography variant="h4">Administration</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Systemmails" {...a11yProps(0)} />
                        <Tab label="User" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <SystemMailOverview
                        systemMailArray={systemMailArray}
                        setSystemMailArray={setSystemMailArray}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <UserOverview
                    profileArray={profileArray}
                    setProfileArray={setProfileArray}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                Item Three
                </CustomTabPanel>
            </>
        )
    }

}