import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { IUser } from "../../Interfaces/IUser";
import { CustomeTextField } from "../generic/CustomeTextField";
import { UserSetPassword } from "../user/UserSetPassword";
import { uploadFetch } from "../../Hooks/useFetch";


const getEmptyUser = () => {
    return {
        id: -1,
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        username: ""
    } as IUser
}



export const Sigup: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [newUser, setNewUser] = useState(getEmptyUser());
    const [isPwValid, setIsPwValid] = useState(false);
    //
    const [hasNoWarning,setHasNoWarning] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    //
    const [isOpenCread,setIsOpenCread] = useState(false);



    const handleOpen = () => {
        setNewUser(getEmptyUser());
        setIsOpen(true)
    }


    const handleClose = () => {
        setIsOpen(false);
    }


    const handleResultSave = (returnObject:any) => {
        //localStorage.setItem('Token', returnObject["token"]);
        //localStorage.setItem('profile', returnObject["profile"]["id"]);
        //localStorage.setItem('isSuperuser', "false");
        //window.location.reload(); 
        setIsOpen(false);
        setIsOpenCread(true);
    }


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setHasNoWarning(true);
        uploadFetch("/user/sigup/", true, newUser, handleResultSave,setHasNoWarning,() => {},setIsLoading)
    }


    return (
        <>
            <Dialog open={isOpenCread} onClose={() => setIsOpenCread(false)}>
                <DialogContent>
                    <Alert severity="info">
                        Please check your mailbox to confirm your email-address.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenCread(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit} autoComplete="on">
                    <DialogTitle>New User</DialogTitle>
                    <DialogContent>
                        <Collapse in={!hasNoWarning}>
                            <Alert severity="info">
                                It seems that the username is already taken.
                            </Alert>
                        </Collapse>

                        <Collapse in={isLoading}>
                            <LinearProgress/>
                        </Collapse>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="First name"
                                    attr="first_name"
                                    type="string"
                                    object={newUser}
                                    setObject={setNewUser}
                                    required
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Last name"
                                    attr="last_name"
                                    type="string"
                                    object={newUser}
                                    setObject={setNewUser}
                                    required
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="E-Mail"
                                    attr="email"
                                    type="email"
                                    object={newUser}
                                    setObject={setNewUser}
                                    required
                                />
                            </Grid>


                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 5 }}>
                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Username"
                                    attr="username"
                                    type="string"
                                    object={newUser}
                                    setObject={setNewUser}
                                    required
                                />
                            </Grid>
                            <UserSetPassword
                                userObject={newUser}
                                setUserObject={setNewUser}
                                setIsValid={setIsPwValid}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit" disabled={!isPwValid}>Save</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Button variant="contained" sx={{ minHeight: 50 }} onClick={handleOpen} fullWidth>sign up</Button>
        </>
    )
}