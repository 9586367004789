    import { Alert, Box, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { NavBar } from "./NavBar";
import { useFetch } from "../../Hooks/useFetch";
import { Discovery } from "../Sites/Discovery";
import { Publish } from "../Sites/Publish";
import { Article } from "../Sites/Article";
import { ProfileView } from "../Profile/ProfileView";
import { Logout } from "@mui/icons-material";
import { Profile } from "../Sites/Profile";
import { ProfileOwn } from "../Sites/ProfileOwn";
import { Home } from "../Sites/Home";
import { AdminOverview } from "../Sites/Admin/AdminOverview";
import { Imprint } from "../SmallSites/Imprint";
import { SearchBar } from "./SearchBar";


export const PageDriver:React.FC = () => {
    const [] = useFetch("/article")

    const checkIsSuperuser = (returnEl: React.ReactElement) => {
        let isSuperuser = Boolean(localStorage.getItem("isSuperuser"))

        if (isSuperuser) {
            return returnEl
        } else {
            return <Alert severity="error">Access denied!</Alert>
        }
    }


    return(
        <BrowserRouter>
            <Grid container>
                <Grid item sm={12} sx={{height: 100, p: 8, mb: 8}}>
                    <Grid container sx={{}}>
                        <Grid item xs={6} sm={6} >
                            <Typography variant="h3">Zeitgeist</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <SearchBar/>
                        </Grid>
                        <Grid item xs={0} sm={3}>
                            <IconButton sx={{float: "right"}} onClick={() => {localStorage.removeItem("Token"); window.location.reload();}}><Logout/></IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sm={3} lg={2} sx={{borderRight:  "1px solid" }}>
                    <NavBar/>
                </Grid>

                <Grid id="body" item sm={6} lg={8}>
                    <Box sx={{m: 10, mt: 0}}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/discovery" element={<Discovery/>}/>
                            <Route path="/publish" element={<Publish/>}/>
                            <Route path="/article/:id" element={<Article/>}/>

                            <Route path="/profile/:id" element={<Profile key="profiles"/>}/>
                            <Route path="/profile/own" element={<Profile key="profiles-own" id={Number(localStorage.getItem("profile"))}/>}/>
                            <Route path="/profile/own/edit" element={<ProfileOwn />} />

                            <Route path="/admin" element={checkIsSuperuser(<AdminOverview/>)} />


                            <Route path="/imprint" element={<Imprint/>}/>
                        </Routes>
                    </Box>
                </Grid>

                <Grid item sm={3} lg={2} sx={{borderLeft:  "1px solid" }}>
                    
                </Grid>
            </Grid>
        </BrowserRouter>
    )
}


