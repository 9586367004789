import React from 'react';
import logo from './logo.svg';
import './App.css';
import { SecurityDriver } from './Components/core/SecurityDriver';
import { ThemeProvider, createTheme } from '@mui/material';
import { PageDriver } from './Components/core/PageDriver';


const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#7B8C92',
    },
    /*
    text: {
      primary: '#7B8C92',
    },
    */
  },

},);


function App() {
  return (
    <ThemeProvider theme={theme}>
      <SecurityDriver>
          <PageDriver/>
      </SecurityDriver>
    </ThemeProvider>
  );
}

export default App;
