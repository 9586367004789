import React, { useEffect, useRef, useState } from "react";
import { IUser } from "../../Interfaces/IUser";
import { IProfile } from "../../Interfaces/IProfile";
import { Alert, Box, Button, Collapse, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { CustomeTextField } from "../generic/CustomeTextField";
import { UserSetPassword } from "../user/UserSetPassword";
import { OpenStreetMapSearch } from "../generic/OpenStreetMapSearch";
import { uploadFetch } from "../../Hooks/useFetch";
import { ProfileAvatar } from "./ProfileAvatar";



interface IOwnUser extends IUser {
    profile: IProfile
}

interface IProps {
    ownUserObject: IOwnUser;
    setOwnUser: Function;
}

export const UserEdit:React.FC<IProps> = ({ownUserObject,setOwnUser}) => {
    const [editOwnUser,setEditOwnUser] = useState<IUser>({...ownUserObject,password: ""});
    const [profilEdit,setProfilEdit] = useState<IProfile>({...ownUserObject.profile});
    //
    const [isSetPwEnabled,setIsSetPwEnabled] = useState(false);
    const [isPwValid, setIsPwValid] = useState(false);
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    //
    const inputUpload = useRef<HTMLInputElement | null>(null);


    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    const handleFilesUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateProfile = {...profilEdit}
            updateProfile.avatar = await fileToBase64(currentFile);

            setProfilEdit({...updateProfile})
        }
    }
    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }



    const onBlur = (pw:string) => {
        if (pw === "") {
            setIsSetPwEnabled(false);
        }
    }

    const handleReset = () => {
        setEditOwnUser({...ownUserObject,password: ""});
        setProfilEdit({...ownUserObject.profile})
    } 


    const handleAfterSave = (updatedUser:IOwnUser) => {
        //window.location.reload();
        setOwnUser(updatedUser);
        setEditOwnUser({...updatedUser,password: ""});
        setProfilEdit({...updatedUser.profile})
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        console.log(editOwnUser);
        let uploadObject = {
            ...editOwnUser,
            profile: profilEdit
        }
        console.log(uploadObject)
        uploadFetch("/profile/own/upadate",false,uploadObject,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
    }

    useEffect(() => {
        console.log(profilEdit)
    },[profilEdit])


    return(
        <>
            <Typography variant="h4">
                Edit your profile
            </Typography>
            
            <Typography variant="h5">
                {editOwnUser.username}    
            </Typography>

            <Collapse in={!wasSuccessfully}>
                <Alert severity="info">
                    A Error occourt!
                </Alert>
            </Collapse>

            <Collapse in={isLoading}>
                <LinearProgress/>
            </Collapse>


            <input 
                hidden 
                ref={inputUpload} 
                type="file" 
                accept="image/png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />
            <form onSubmit={handleSubmit} autoComplete="on">
                <Box sx={{mt: 5, display: "flex"}}>

                    <button type="button" onClick={() => (inputUpload !== null) && inputUpload.current?.click()}  style={{borderRadius: "50%", backgroundColor: "white"}}>
                        <ProfileAvatar profileObject={profilEdit} username={editOwnUser.username} size="big"/>
                    </button>
                </Box>
                


                <Typography variant="h5" sx={{mt: 10, mb: 2}}>Private informations</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Name:</b></Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <CustomeTextField
                            label="First name"
                            attr="first_name"
                            type="string"
                            object={editOwnUser}
                            setObject={setEditOwnUser}
                            required
                            size="small"
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <CustomeTextField
                            label="Last name"
                            attr="last_name"
                            type="string"
                            object={editOwnUser}
                            setObject={setEditOwnUser}
                            required
                            size="small"
                        />
                    </Grid>
                

                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Birthday:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="Birthday"
                            attr="birth_date"
                            type="date"
                            object={editOwnUser}
                            setObject={setEditOwnUser}
                            size="small"
                            null
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>E-Mail:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="E-Mail"
                            attr="email"
                            type="string"
                            object={editOwnUser}
                            setObject={setEditOwnUser}
                            required
                            size="small"
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Password:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <Collapse in={isSetPwEnabled}>
                            <Grid container>
                                <UserSetPassword
                                    userObject={editOwnUser}
                                    setUserObject={setEditOwnUser}
                                    setIsValid={setIsPwValid}
                                    onBlur={onBlur}
                                />
                            </Grid>
                        </Collapse>

                        <Collapse in={!isSetPwEnabled}>

                            <TextField
                                label="Password"
                                value={"Secr***"}
                                size="small"
                                fullWidth
                                onFocus={() => setIsSetPwEnabled(true)}
                            />
                        </Collapse>

                    </Grid>
                </Grid>


                <Typography variant="h5" sx={{mt: 10, mb: 2}}>Public informations</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Username:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <TextField
                            label="Username"
                            value={editOwnUser.username}
                            size="small"
                            disabled
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Location:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <OpenStreetMapSearch
                            stringValue= {profilEdit.location}
                            setStringValue={(location:string) => setProfilEdit({...profilEdit, location:location })}
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Typography sx={{mt: 1}}><b>Description:</b></Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="Description"
                            attr="bio"
                            type="string"
                            object={profilEdit}
                            setObject={setProfilEdit}
                            size="small"
                            rows={3}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:  15}}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="submit"  variant="contained" disabled={isLoading || (isSetPwEnabled && !isPwValid)}>
                        Save
                    </Button>
                </Box>
            </form>
        </>
    )
}