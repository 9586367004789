import { Autocomplete, CircularProgress, TextField, createFilterOptions } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


interface ISearchResults {
    title: string;
    type: "profile" | "article";
    id: number;
    search: string;
    //value: string;
}



export const SearchBar:React.FC = () => {
    const navigation = useNavigate();
    //
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    //
    const [currentOption,setCurrentOption] = useState<ISearchResults|null>(null);
    const [options,setOptions] = useState<ISearchResults[]>([]);
    //
    const [searchTerm,setSearchTerm] = useState("");



    function onChange(event: SyntheticEvent, newObject:ISearchResults|null) {
        if (newObject === null) {
            setCurrentOption(newObject);
        }
        else {
            if (newObject.type === "profile") {
                navigation(`/profile/${newObject.id}`)
            }
            else {
                navigation(`/article/${newObject.id}`)
            }
            setSearchTerm("");
            setCurrentOption(null);
        }

    }
  
  
    function onInputChange(event: SyntheticEvent | null, searchTerm:string) {
        if (event !== null && event.type === "change") {
            setLoading(true);
            setSearchTerm(searchTerm);
        }
    }

    async function queryBackend(localSearchTerm:string) {
        //setOptions([]);
        setLoading(true);
        console.log(localSearchTerm)
        if (localSearchTerm === "") {
          setLoading(false);
          //setOptions([]);
        }
        else{
            fetch(`/api/search/profile_or_article/${searchTerm}`, {
                headers: { 'Content-Type': 'application/json', "Authorization" : "Token " + localStorage.getItem('Token') },
            })
            .then(res => {
                if (res.status === 200) { return res.json()}
                else {setLoading(false);}
            })
            .then(res => {
                let localObject = res;
                if (Array.isArray(res)) {
                    setOptions(res);
                }
                setLoading(false);
            })
        }
      }


    useEffect(() => {
        const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
        return () => clearTimeout(timeOutId);
    },[searchTerm])
  
    useEffect(() => {
        console.log(options)
    },[options])

    const filterOptions = createFilterOptions({
        stringify: (option:ISearchResults) => option.search
    });
    
    return (
        <Autocomplete
          filterOptions={filterOptions}
          id="searchbar-location"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={currentOption}
          //isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => `${option.title}` }
          loadingText="Search..."
          blurOnSelect={true}
          options={options}
          loading={loading}
          onInputChange={onInputChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
}