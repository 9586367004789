import React, { useState } from "react";
import { CustomeTextField } from "../generic/CustomeTextField";
import { IArticle, IArticleFullObject } from "../../Interfaces/IArticle";
import { Alert, Box, Button, Collapse, Grid, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { uploadFetch } from "../../Hooks/useFetch";
import { IProfileFullObject } from "../../Interfaces/IProfile";
import { ArticleEdit } from "../Article/ArticleEdit";
import { IArticleParagraph } from "../../Interfaces/IArticleParagraph";


const getEmptyArticle = () => {
    return {
        id: -1,
        Created_at: "",
        Title: "",
        Author_id: -1,
        Author: {} as IProfileFullObject,
        paragraphs: [{id: 1, Article: -1, position: 1, text: "" } as IArticleParagraph],
        pictures: [],
        Description: ""

    } as IArticleFullObject
}



export const Publish:React.FC = () => {
    const navigation = useNavigate();

    const [newArticle,setNewArticle] = useState(getEmptyArticle());
    const [paragraphs,setParagraphs] = useState(newArticle.paragraphs);
    const [pictures,setPictures] = useState(newArticle.pictures);
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoading,setIsLoading] = useState(false);


    const handleAfterSave = (newArticle:IArticleFullObject) => {
        navigation(`/article/${newArticle.id}`)
    }


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let uploadObject:IArticleFullObject = {
            ...newArticle,
            paragraphs: paragraphs,
            pictures: pictures
        }
        uploadFetch("/article/",true,uploadObject,handleAfterSave,setWasSuccessfully, () => {},setIsLoading)
    }

    const handleReset = () => {
        setNewArticle(getEmptyArticle())
    }


    return(
        <>
            <Collapse in={!wasSuccessfully}>
                <Alert severity="info">
                    An error occurred!
                </Alert>
            </Collapse>

            <Collapse in={isLoading}>
                <LinearProgress/>
            </Collapse>


            <form onSubmit={handleSubmit} autoComplete="on">
                <ArticleEdit
                    editArticle={newArticle}
                    setEditArticle={setNewArticle}
                    paragraphs={paragraphs}
                    setParagraphs={setParagraphs}
                    pictures={pictures}
                    setPictures={setPictures}
                />

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:  15}}>
                    <Button
                        variant="outlined"
                        onClick={handleReset}
                        sx={{ mr: 1 }}
                    >
                        Reset
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="submit"  variant="contained" disabled={isLoading}>
                        Publish
                    </Button>
                </Box>
            </form>
        </>
    )
}