import React from "react";
import { getFetch, useFetch } from "../../Hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { IProfile, IProfileFullObject } from "../../Interfaces/IProfile";
import { IArticleFullObject } from "../../Interfaces/IArticle";
import { Alert, Avatar, Box, Button, CircularProgress, Collapse, Grid, Typography } from "@mui/material";
import { ArticleCard } from "../Article/ArticleCard";
import { ProfileAvatar } from "./ProfileAvatar";
import { LocationOn } from "@mui/icons-material";

interface IProps {
    profileObject: IProfileFullObject;
    setProfileObject: Function;
    articleArray: IArticleFullObject[];
    profileId: number;
}




export const ProfileView:React.FC<IProps> = ({ profileObject, setProfileObject, articleArray, profileId }) => {
    const navigation = useNavigate();



    const handleFollow = (add:boolean) => {
        if (profileObject !== undefined) { 
            if (add) {
                setProfileObject({
                    ...profileObject,
                    followers: [...profileObject.followers,profileId]
                } as IProfileFullObject)
            }
            else {
                setProfileObject({
                    ...profileObject,
                    followers: [...profileObject.followers.filter(x => x !== profileId)]
                } as IProfileFullObject)
            }
        }
    }

    return(
        <>
            <Box sx={{ display: "flex"}}>
                <Box>
                    <ProfileAvatar profileObject={profileObject} username={profileObject.user.username} size="medium"/>
                </Box>
                <Box sx={{ml: 10, width: "100%"}}>
                    <Typography sx={{mt: 2,}} variant="h3" >
                        {profileObject.user.username}

                        <Box sx={{float: "right", display: "flex"}}>
                            <Typography color="text.secondary" sx={{mt: 1, mr: 2}}>{profileObject.user.username} has {profileObject.followers.length} followers</Typography>
                            {
                                (profileObject.id === profileId) 
                                ? <Button variant="outlined" onClick={() => navigation("/profile/own/edit")}>Edit</Button>
                                :(profileObject.followers.indexOf(profileId) > -1) 
                                    ? <Button variant="contained" onClick={() => getFetch("/profile/unfollow/",profileObject.id,() => handleFollow(false)) }>unfollow</Button>
                                    : <Button variant="outlined" onClick={() => getFetch("/profile/follow/",profileObject.id,() => handleFollow(true)) }>follow</Button>
                            }
                            
                        </Box>
                    </Typography>

                    {(profileObject.location === "")
                        ? <Box sx={{m: 5}}/>
                        :
                            <Box sx={{display: "flex", fontSize: 3,mb: 5}}>
                                <LocationOn sx={{ fontSize: 15}}/><Typography sx={{ml: 1, fontSize: 15}}>{profileObject.location}</Typography>
                            </Box>
                    }


                    <Box sx={{display: "block"}}>
                        {(profileObject.bio === "")
                            ? <i>This User has no description</i>
                            : <>{profileObject.bio}</>
                        }
                    </Box>
                </Box>
            </Box>

            <Typography sx={{mt: 15, textAlign: "center", mb: 5}} variant="h4">Article</Typography>
            {articleArray.map(x =>
                <ArticleCard key={`article-${x.id}`} articleObject={x}/>
            )}
        </>
    )
    
}