import React, { useState } from "react";
import { getFetch, useFetch } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import { IProfile, IProfileFullObject } from "../../Interfaces/IProfile";
import { IArticleFullObject } from "../../Interfaces/IArticle";
import { Alert, Avatar, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { ArticleCard } from "../Article/ArticleCard";
import { ProfileView } from "../Profile/ProfileView";


interface IProps {
    id?:number;
}



export const Profile:React.FC<IProps> = ({id}) => {
    const params = useParams();
    const [usedId,setUsedId] = useState((id === undefined) ? Number(params.id) : id);
    const [profileObject, setProfileObject, statusCodeProfile] = useFetch<IProfileFullObject>("/profile/",usedId);
    const [articleArray,setArticleArray,statusCodeArticle] = useFetch<IArticleFullObject[]>("/article/byProfile/",usedId);
    const profileId = Number(localStorage.getItem("profile"));

    const handleFollow = (add:boolean) => {
        if (profileObject !== undefined) { 
            if (add) {
                setProfileObject({
                    ...profileObject,
                    followers: [...profileObject.followers,profileId]
                } as IProfileFullObject)
            }
            else {
                setProfileObject({
                    ...profileObject,
                    followers: [...profileObject.followers.filter(x => x !== profileId)]
                } as IProfileFullObject)
            }
        }
    }

    if ((statusCodeProfile !== null && statusCodeProfile !== 200) || (statusCodeArticle !== null && statusCodeArticle !== 200))
        { return <Alert severity="error">Error!</Alert> }
    else if (profileObject === undefined || articleArray === undefined) 
        { return <CircularProgress/> }
    else {
        return(
            <>
                <ProfileView
                    profileId={profileId}
                    profileObject={profileObject}
                    setProfileObject={setProfileObject}
                    articleArray={articleArray}
                />
            </>
        )
    }
}