import React from "react";
import { ISystemMail } from "../../../Interfaces/ISystemMail";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { SystemMailRow } from "./SystemMailRow";


interface IProps {
    systemMailArray: ISystemMail[];
    setSystemMailArray: Function;
}



export const SystemMailOverview:React.FC<IProps> = ({systemMailArray,setSystemMailArray}) => {
    return(
        <>
            <Typography variant="h6">
                Systemmails
            </Typography>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 100}}>Nr</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell sx={{width: 100}}>Actions</TableCell>
                    </TableRow>
                </TableHead>


                <TableBody>
                    {systemMailArray.map(x =>
                        <SystemMailRow
                            key={`systemmail-${x.id}`}
                            systemMailObject={x}
                            systemMailArray={systemMailArray}
                            setSystemMailArray={setSystemMailArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}