import React, { useEffect } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { Alert, CircularProgress } from "@mui/material";
import { IArticleFullObject } from "../../Interfaces/IArticle";
import { ArticleCard } from "../Article/ArticleCard";


export const Home:React.FC = () => {
    const [articleArray,setArticleArray,statusCode] = useFetch<IArticleFullObject[]>("/article/feed/");



    if (statusCode !== 200 && statusCode !== null) { return <Alert severity="info">Error</Alert> }
    else if (articleArray === undefined) { return <CircularProgress/> }
    else {
        return(
            <>
                {articleArray.map(x =>
                    <ArticleCard key={`article-${x.id}`} articleObject={x}/>
                )}
            </>
        )
    }


}