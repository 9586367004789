import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uploadFetch } from "../../Hooks/useFetch";
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";



export const Validate:React.FC = () => {
    const params = useParams();
    const navigaiton = useNavigate();
    //
    const [isLoading,setIsLoading] = useState(true);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    //
    const [isOpen,setIsOpen] = useState(true);

    const handleAfterSave = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        navigaiton("/");
    }

    useEffect(() => {
        let uploadObject:any = {
            "id" : Number(params.id),
            "AuthToken" : params.authToken
        }
        uploadFetch("/user/actived/", true, uploadObject, handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
    },[])

    
    if (isLoading) { return <CircularProgress /> }
    else {
        return(
            <>
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogContent>
                        {(wasSuccessfully)
                            ? <Alert severity="success">Your e-mail-adress is confirmed. Please login and enjoy.</Alert>
                            : <Alert severity="error">The entered activation code has expired</Alert>
                        }
                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

}