import React from "react";
import { IArticle } from "../../Interfaces/IArticle";
import { IArticleParagraph } from "../../Interfaces/IArticleParagraph";
import { IArticlePicture } from "../../Interfaces/IArticlePicture";
import { Box, Button, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { CustomeTextField } from "../generic/CustomeTextField";
import { ArticleParagraphEdit } from "./Childs/ArticleParagraphEdit";
import { Add, Delete } from "@mui/icons-material";
import { ArticlePictureEdit } from "./Childs/ArticlePictureEdit";


interface IProps {
    editArticle: IArticle;
    setEditArticle: Function;
    paragraphs: IArticleParagraph[];
    setParagraphs: Function;
    pictures: IArticlePicture[];
    setPictures: Function;
}


export const ArticleEdit:React.FC<IProps> = ({editArticle, setEditArticle, paragraphs, setParagraphs, pictures, setPictures}) => {
    
    const genStructure = () => {
        let returnComponents:React.ReactElement[] = [];
        let maxPosParagraphs =0;
        let maxPosPicture =0; 
        let maxPos = 0;

        if (paragraphs.length > 0) {
            maxPosParagraphs = Math.max(...paragraphs.map(x => x.position))
        } 
        if (pictures.length > 0) {
            maxPosPicture = Math.max(...pictures.map(x => x.position));
        } 

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        Array.from({ length: maxPos }, (x, i) => {
            let foundParagraph = paragraphs.find(x => x.position === (i+1));
            let foundPicture = pictures.find(x => x.position === (i+1));

            returnComponents.push(
                <>
                    {(foundParagraph) && 
                        <Grid item sm={12}>
                            <ArticleParagraphEdit
                                key={`paragraph-${foundParagraph.id}-${foundParagraph.position}`}
                                articleParagraph={foundParagraph}
                                paragraphs={paragraphs}
                                setParagraphs={setParagraphs}
                                pictures={pictures}
                                setPictures={setPictures}
                            />
                        </Grid>


                    }
                    {(foundPicture) && 
                        <Grid item sm={12}>
                            <ArticlePictureEdit
                                key={`picture-${foundPicture.id}-${foundPicture.position}`}
                                articlePicture={foundPicture}
                                pictures={pictures}
                                setPictures={setPictures}
                                paragraphs={paragraphs}
                                setParagraphs={setParagraphs}
                            />
                        </Grid>
                    }

                </>
            )
        })

        return(
            <>{[...returnComponents]}</>
        )
    }

    const handleAddText = () => {
        let returnComponents:React.ReactElement[] = [];
        let maxPosParagraphs = 0;
        let maxPosPicture = 0;
        let maxPos = 0;

        if (paragraphs.length > 0) {
            maxPosParagraphs = Math.max(...paragraphs.map(x => x.position))
        } 
        if (pictures.length > 0) {
            maxPosPicture = Math.max(...pictures.map(x => x.position));
        } 

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        let tmpId = -1;
        if (paragraphs.length > 0) {
            let minTmpId = Math.min(...paragraphs.map(x => x.id)) -1;

            if (minTmpId <= tmpId) {
                tmpId = minTmpId -1;
            }
        }


        let newObject:IArticleParagraph = {
            id: tmpId,
            Article: -1,
            position: maxPos+1,
            text: ""
        }

        setParagraphs([
            ...paragraphs,
            newObject
        ])
    }

    const handleAddPicture = () => {
        let returnComponents:React.ReactElement[] = [];
        let maxPosParagraphs = Math.max(...paragraphs.map(x => x.position));
        let maxPosPicture = Math.max(...pictures.map(x => x.position));
        let maxPos = 0;

        if (paragraphs.length > 0) {
            maxPosParagraphs = Math.max(...paragraphs.map(x => x.position))
        } 
        if (pictures.length > 0) {
            maxPosPicture = Math.max(...pictures.map(x => x.position));
        }

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        let tmpId = -1;
        if (pictures.length > 0) {
            let minTmpId = Math.min(...pictures.map(x => x.id)) -1;

            if (minTmpId <= tmpId) {
                tmpId = minTmpId -1;
            }
        }



        let newObject:IArticlePicture = {
            id: tmpId,
            Article: -1,
            position: maxPos+1,
            picture: "",
            pictureName: ""
        }

        setPictures([
            ...pictures,
            newObject
        ])
    }

    return(
        <>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <CustomeTextField
                        attr="Title"
                        label="Title"
                        type="string"
                        object={editArticle}
                        setObject={setEditArticle}
                        required
                        variant="standard"
                    />
                </Grid>

                {genStructure()}

                <Grid item sm={12}>
                    <Button onClick={handleAddText}><Add/>Text</Button>
                    <Button sx={{ml: 2}} onClick={handleAddPicture}><Add/>Picture</Button>
                </Grid>
            </Grid>

        </>
    )
} 