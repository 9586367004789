import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

interface IOpenStreetMapResult {
    place_id: number;
    display_name: string;
}


interface IProps {
    stringValue: string;
    setStringValue: Function;
}


function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }


export const OpenStreetMapSearch:React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [openStreetMapObject,setOpenStreetMapObject] = useState<IOpenStreetMapResult|null>(null);
    const [options, setOptions] = useState<readonly IOpenStreetMapResult[]>([]);
    //
    // const loading = open && options.length === 0;
    const [loading,setLoading] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");

    useEffect(() => {
        if (props.stringValue !== "") {
            let selectedObject = { place_id: 1, display_name: props.stringValue } as IOpenStreetMapResult;
            setOptions([selectedObject])
            setOpenStreetMapObject(selectedObject)
        }
    },[])




    async function queryBackend(localSearchTerm:string) {
      setLoading(true);

      if (localSearchTerm === "") {
        setLoading(false);
        setOptions([]);
      }
      else{
        fetch(`https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json`, {
          headers: {
            'User-Agent': 'zeitgiest-location-search'
          }
        }).then(res => res.json())
          .then(res => {
            let localObject = res;
            if (Array.isArray(res)) {
              setOptions(res);
            }
            setLoading(false);
            
        })
        //setSearchTerm("");
      }
    }

    function onChange(event: SyntheticEvent, newObject:IOpenStreetMapResult|null) {
      setOpenStreetMapObject(newObject);
      if (newObject !== null) {
        props.setStringValue(newObject.display_name);
      }
    }


    function onInputChange(event: SyntheticEvent | null, searchTerm:string) {
      if (event !== null && event.type === "change") {
        setLoading(true);
        setSearchTerm(searchTerm);
      }
    }


    useEffect(() => {
      const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
      return () => clearTimeout(timeOutId);
    },[searchTerm])




    return (
        <Autocomplete
          id="searchbar-location"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={openStreetMapObject}
          isOptionEqualToValue={(option, value) => option.display_name === value.display_name}
          getOptionLabel={(option) => `${option.display_name}` }
          loadingText="Search..."
          options={options}
          loading={loading}
          onInputChange={onInputChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Location"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
}
