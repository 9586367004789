import React, { useState } from "react";
import { IADMINProfile } from "../../../Interfaces/IProfile";
import { getFetch, uploadFetch } from "../../../Hooks/useFetch";
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { IADMINUser } from "../../../Interfaces/IUser";


interface IProps {
    idProfile: number;
    setIdProfile: Function;
    profileArray: IADMINUser[];
    setProfileArray: Function;
}


export const UserEdit:React.FC<IProps> = ({idProfile, setIdProfile, profileArray, setProfileArray}) => {
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    //
    const [wasSuccessfullyReset,setWasSuccessfullyReset] = useState(true);
    const [isLoadingNewPw,setIsLoadingPw] = useState(false);
    const [newPw,setNewPw] = useState("")
    //
    const [editUser,setEditUser] = useState(() => {
        let testObject = profileArray.find(x => x.id === idProfile);

        if (testObject !== undefined) {
            return testObject;
        }
        else {
            return {

                date_joined: "",
                id: -1,
                is_active: true,
                is_superuser: false,
                username: "",
                email: ""

            } as IADMINUser
        }
    })

    const handleAfterReset = (newPw:{PW: string}) => {
        setNewPw(newPw.PW);
    }

    const handleNewPw = (id: number) => {
        getFetch(`/user/admin/${id}/reset`,undefined,handleAfterReset,setWasSuccessfullyReset,setIsLoadingPw)
    }


    const handleAfterSave = (updatedUser:IADMINUser) => {
        let testObject = profileArray.find(x => x.id === updatedUser.id);
        if (testObject === undefined) {
            setProfileArray([
                ...profileArray,
                updatedUser
            ])
            handleNewPw(updatedUser.id);
        }
        else {
            setProfileArray([
                ...profileArray.map(x => x.id === updatedUser.id ? updatedUser : x)
            ])
            setIdProfile(null);
        }
        
    }


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (editUser.id < 0) {
            uploadFetch("/user/admin/",true,editUser,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
        } else {
            uploadFetch(`/user/admin/${editUser.id}`,false,editUser,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
        }
        
    }

    const handleCloseNewPw = () => {
        if (idProfile < 0) {
            setIdProfile(null);
        }
        else {
            setNewPw("");
        }
    }


    if (isLoading || isLoadingNewPw) {
        return <CircularProgress/>
    }
    else {
        return(
            <Box sx={{mt: 3}}>
                <Dialog open={newPw !== ""} onClose={handleCloseNewPw} sx={{mb: 2}}>

                    <DialogContent>
                        <TextField
                            label="New password"
                            value={newPw}
                            disabled
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseNewPw}>Close </Button>
                    </DialogActions>
                </Dialog>

                <Collapse in={!wasSuccessfullyReset} sx={{mb: 2}}>
                    <Alert severity="warning">
                        Can't reset password from user.
                        <br/>Maybe the email doesn't exist.
                    </Alert>
                </Collapse>

                <Collapse in={!wasSuccessfully} sx={{mb: 2}}>
                    <Alert severity="warning">
                        Can't user current user.
                        <br/>Maybe the username or the email is already taken.
                    </Alert>
                </Collapse>
    
    
                <form onSubmit={handleSubmit} autoComplete="on">
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Username"
                                attr="username"
                                type="string"
                                object={editUser}
                                setObject={setEditUser}
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Email"
                                attr="email"
                                type="string"
                                object={editUser}
                                setObject={setEditUser}
                                required
                                size="small"
                            />
                        </Grid>
    
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Admin"
                                attr="is_superuser"
                                type="boolean"
                                object={editUser}
                                setObject={setEditUser}
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Active"
                                attr="is_active"
                                type="boolean"
                                object={editUser}
                                setObject={setEditUser}
                                required
                                size="small"
                            />
                        </Grid>
                    </Grid>
    
                    <Collapse in={idProfile > 0}>
                        <Box sx={{mt: 2}}>
                            <Button variant="outlined" onClick={() => handleNewPw(editUser.id)} fullWidth>Reset password</Button>
                        </Box>
                    </Collapse>

    
                    <Box sx={{mt: 5}}>
                        <Button sx={{float: "right", ml: 2}} variant="contained" type="submit">Save</Button>
                        <Button sx={{float: "right"}} variant="outlined" onClick={() => setIdProfile(null)}>Close</Button>
                    </Box>
                    
                </form>
            </Box>
        )
    }

}