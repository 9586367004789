import React, { useState } from "react";
import { ISystemMail } from "../../../Interfaces/ISystemMail";
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, LinearProgress, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { SystemMailEdit } from "./SystemMailEdit";
import { uploadFetch } from "../../../Hooks/useFetch";


interface IProps {
    systemMailObject: ISystemMail;
    systemMailArray: ISystemMail[];
    setSystemMailArray: Function;
}


export const SystemMailRow:React.FC<IProps> = ({systemMailObject, systemMailArray, setSystemMailArray}) => {
    const [editSystemMailObject,setEditSystemMailObject] = useState(systemMailObject);
    const [isOpen,setIsOpen] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccessfully,setWasSuccessfully] = useState(true);



    const handleOpen = () => {
        setEditSystemMailObject(systemMailObject);
        setIsOpen(true);
    }


    const handleAfterSave = () => {
        setSystemMailArray([
            ...systemMailArray.map(x => x.id === editSystemMailObject.id ? editSystemMailObject :x )
        ])
        setIsOpen(false)
    }


    const handleSave = () => {
        uploadFetch(`/systemmail/${editSystemMailObject.id}`,false, editSystemMailObject,handleAfterSave,setWasSuccessfully,() => {}, setIsLoading)
    }


    return(
        <>
            <Dialog open={isOpen} onClose={setIsOpen} maxWidth="lg" fullWidth>
                <DialogTitle>Edit systemmail</DialogTitle>
                <DialogContent>
                    <>
                        <Collapse in={!wasSuccessfully}>
                            <Alert severity="info">
                                A Error occourt!
                            </Alert>
                        </Collapse>

                        <Collapse in={isLoading}>
                            <LinearProgress/>
                        </Collapse>
                        <SystemMailEdit
                            systemMailObject={editSystemMailObject}
                            setSystemMailObject={setEditSystemMailObject}
                        />
                    </>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Close</Button>
                    <Button variant="contained" onClick={handleSave} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>


            <TableRow>
                <TableCell>{systemMailObject.id}</TableCell>
                <TableCell>{systemMailObject.Title}</TableCell>
                <TableCell>
                    <IconButton size="small" onClick={handleOpen}><Edit/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}