import React, { useEffect, useState } from "react";
import { IArticle, IArticleFullObject } from "../../Interfaces/IArticle";
import { Avatar, Box, Card, CardContent, CircularProgress, Grid, IconButton, Link, Typography } from "@mui/material";
import { Facebook, Instagram, LinkedIn, MoreHoriz, Twitter, WhatsApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ProfileAvatar } from "../Profile/ProfileAvatar";
import { IProfileFullObject } from "../../Interfaces/IProfile";
import { getFetch } from "../../Hooks/useFetch";
import { FacebookShareButton, InstapaperShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";


interface IProps {
    articleObject: IArticle;
    disableMoreButton?: boolean;
    children?: React.ReactElement;
}



export const ArticleCard:React.FC<IProps> = ({articleObject, disableMoreButton, children}) => {
    const navigation = useNavigate();
    const [showAll,setShowAll] = useState(false);
    //
    const [profileObject,setProfileObject] = useState<IProfileFullObject | undefined>(undefined);


    useEffect(() => {
      if (typeof articleObject.Author === "number") {
        getFetch("/profile/",articleObject.Author,setProfileObject)
      }
      else {
        setProfileObject(articleObject.Author)
      }
    },[articleObject])


    return(
        <>
          <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <Box sx={{mb: 7, width: 700}}>
              <Grid container spacing={1.5}>
                <Grid item sm={8}>
                  <Typography sx={{ fontSize: 14, display: "flex" }}>
                      {(profileObject === undefined) 
                        ? <CircularProgress size={25}/>
                        :
                          <>
                            <ProfileAvatar profileObject={profileObject} username={profileObject.user.username}/>
                            <Typography sx={{ml: 2, mt: 0.3}}  color="text.primary">
                              <>
                                <Link onClick={() => navigation(`/profile/${profileObject.id}`)} component="button">{profileObject.user.username}</Link> &#8226; {}
                              </>
                            </Typography>
                          </>
                      }

                      <Typography sx={{ml: 2, mt: 0.3}}  color="text.secondary">
                        {new Date(articleObject.Created_at).toLocaleDateString()}
                      </Typography>
                  </Typography>
                </Grid>

                <Grid item sm={4}>


                  {(disableMoreButton === undefined || disableMoreButton === false) ?
                    <IconButton size="small" sx={{float: "right"}} onClick={() => navigation(`/article/${articleObject.id}`)}><MoreHoriz/></IconButton>
                    :
                      <Box sx={{float: "right"}}>
                        <InstapaperShareButton url={`https://www.zeitgeist.eu/article/${articleObject.id}`}>
                          <Instagram/>
                        </InstapaperShareButton>
                        <FacebookShareButton url={`https://www.zeitgeist.eu/article/${articleObject.id}`}>
                          <Facebook/>
                        </FacebookShareButton>
                        <WhatsappShareButton url={`https://www.zeitgeist.eu/article/${articleObject.id}`}>
                          <WhatsApp/>
                        </WhatsappShareButton>
                        <TwitterShareButton url={`https://www.zeitgeist.eu/article/${articleObject.id}`}>
                          <Twitter/>
                        </TwitterShareButton>
                        <LinkedinShareButton url={`https://www.zeitgeist.eu/article/${articleObject.id}`}>
                          <LinkedIn/>
                        </LinkedinShareButton>
                      </Box>
                  }
                </Grid>


                <Grid item sm={12}>
                  <Typography variant="h6"  color="text.primary">
                    {articleObject.Title}
                  </Typography>
                </Grid>

                  {/*
                {(children === undefined) &&
                  <Grid item sm={12}>
                    <Typography color="text.primary">
                    <>
                            {(articleObject.paragraphs.length > 0)
                                && articleObject.paragraphs[0].text.length
                            }
                            {(articleObject.paragraphs.length === 0)
                                ? <i>Kein Text vorhanden.</i>
                                : 
                                (articleObject.paragraphs[0].text.length > 160)
                                    ? `${articleObject.paragraphs[0].text.slice(0,160)}...`
                                    : articleObject.paragraphs[0].text
                            }
                    </>
                    </Typography>
                  </Grid>
                }
                  */}


              {(children === undefined) &&
                  <Grid item sm={12}>
                    <Typography color="text.primary">
                    <>
                      {(articleObject.Description === "")
                        ? <i>Kein Text vorhanden.</i>
                        : (articleObject.Description.length > 160)
                          ? `${articleObject.Description.slice(0,160)}...`
                          : articleObject.Description
                      }
                    </>
                    </Typography>
                  </Grid>
                }
              </Grid>

              {(children !== undefined) && children}
            </Box>
          </Box>
        </>
    )
}