import React, { useState } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { CircularProgress } from "@mui/material";
import { Login } from "../Login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Validate } from "../Login/Validate";
import { ConfirmResetPassword } from "../Login/ConfirmResetPassword";
import { PublicImprint } from "../Sites/Public/PublicImprint";


interface IProps {
    children: React.ReactElement;
}



export const SecurityDriver:React.FC<IProps> = (props) => {
    const [result,setResult,statusCode] = useFetch("/user/validate/");
    const [isLogged,setIsLogged] = useState(false);
    const [isLoadin,setIsLoadin] = useState(true);


    if (statusCode === null ) { return <CircularProgress/> }
    else if (statusCode === 200) { return props.children } 
    else { return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/validate/:id/:authToken" element={<Validate/>}/>
                    <Route path="/password/reset/:id/:authToken" element={<ConfirmResetPassword/>}/>
                    <Route path="/imprint" element={<PublicImprint/>}/>

                    <Route path="*" element={<Login/>}/>
                    
                </Routes>
            </BrowserRouter>
        </>
    ) }

}