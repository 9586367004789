import React, { useEffect, useState } from "react";
import { IArticleParagraph } from "../../../Interfaces/IArticleParagraph";
import { Box, Grid, IconButton } from "@mui/material";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { IArticlePicture } from "../../../Interfaces/IArticlePicture";


interface IProps {
    articleParagraph: IArticleParagraph;
    paragraphs: IArticleParagraph[];
    setParagraphs: Function;
    pictures: IArticlePicture[];
    setPictures: Function;
}



export const ArticleParagraphEdit:React.FC<IProps> = ({articleParagraph,paragraphs,setParagraphs, pictures, setPictures}) => {
    const [editParagraph, setEditParagraph] = useState(articleParagraph);

    useEffect(() => {
        setParagraphs([
            ...paragraphs.map(x => x.id === editParagraph.id ? editParagraph : x)
        ])
    },[editParagraph])


    const handleDelete = () => {
        let current = editParagraph.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position > current) ? {...x, position: x.position-1} : x);
        localPictures = localPictures.map(x => (x.position > current) ? {...x, position: x.position-1} : x);

        setPictures([
            ...localPictures
        ])

        setParagraphs([
            ...localParagrap.filter(x => x.id !== editParagraph.id)
        ])
    }

    const handleUp = () => {
        let current = editParagraph.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position === current-1) ? {...x, position: x.position+1} : x);
        localPictures = localPictures.map(x => (x.position === current-1) ? {...x, position: x.position+1} : x);

        setPictures([
            ...localPictures
        ])

        setParagraphs([
            ...localParagrap.map(x => x.id === editParagraph.id ? {...editParagraph, position: current-1} : x)
        ])
    }

    const handleDown = () => {
        let current = editParagraph.position;
        let localParagrap = [...paragraphs];
        let localPictures = [...pictures];

        localParagrap = localParagrap.map(x => (x.position === current+1) ? {...x, position: x.position-1} : x);
        localPictures = localPictures.map(x => (x.position === current+1) ? {...x, position: x.position-1} : x);

        setPictures([
            ...localPictures
        ])

        setParagraphs([
            ...localParagrap.map(x => x.id === editParagraph.id ? {...editParagraph, position: current+1} : x)
        ])
    }

    const disableDown = () => {
        let maxPosParagraphs =0;
        let maxPosPicture =0; 
        let maxPos = 0;

        if (paragraphs.length > 0) {
            maxPosParagraphs = Math.max(...paragraphs.map(x => x.position))
        } 
        if (pictures.length > 0) {
            maxPosPicture = Math.max(...pictures.map(x => x.position));
        } 

        if (maxPosParagraphs > maxPosPicture) {
            maxPos = maxPosParagraphs;
        } else {
            maxPos = maxPosPicture;
        }

        return (articleParagraph.position === maxPos);
    }


    return(
        <>
            <Box sx={{display: "flex"}}>
                <Box sx={{width: "100%"}}>
                    <CustomeTextField
                        attr="text"
                        label=""
                        type="string"
                        object={editParagraph}
                        setObject={setEditParagraph}
                        required
                        rows={5}
                    />
                </Box>
                <Box sx={{width: 20, ml: 2}}>
                    <IconButton onClick={handleUp} disabled={editParagraph.position === 1}><ArrowUpward/></IconButton>
                    <IconButton onClick={handleDown} disabled={disableDown()}><ArrowDownward/></IconButton>
                    <IconButton onClick={handleDelete}><Delete/></IconButton>
                </Box>
            </Box>
        </>
    )
}